export default () => ({
  currentSequenceId: "",
  currentUnitId: "",
  unitsBySequenceId: [],
  currentMenuItem: "modules",
  moduleToCheckMC: null,
  scormStatus: null,
  iframeContentStatus: null,
  expandVleSideNav: false,
  credentials: null,
  milestones: []
});
