<template>
  <div
    id="app"
    @click="onPageClick($event)"
    :class="[`app-${language}`, getClassBasedOnRoute]"
    :dir="language === 'ar' ? 'rtl' : 'ltr'"
  >
    <ToastWrapper />
    <div class="h-100" v-if="showMaintenancePage">
      <MaintenancePage />
    </div>
    <div class="h-100" v-else>
      <div class="h-100 app__router-view-wrapper" v-if="isRouterView">
        <!-- Dynamic layout wrapper components Inspired by https://markus.oberlehner.net/blog/dynamic-vue-layout-components/#dynamic-layout-wrapper-components -->
        <component :is="layout">
          <router-view :layout.sync="layout" :key="$route.fullPath" />
        </component>
      </div>
      <div class="h-100" v-else>
        <ErrorPage />
      </div>
    </div>

    <div
      class="ul-tour__backdrop"
      id="ul-tour__backdrop"
      v-if="vueTourStatus"
    ></div>
    <div
      class="app__full-page-loader-wrapper"
      :class="{
        'app__full-page-loader-wrapper--active': getShowFullPageLoader === true
      }"
    >
      <div class="app__full-page-loader-container">
        <Loader />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import userLanguageMixin from "@/core/mixins/userLanguage";
import ErrorPage from "./views/ErrorPage.vue";
import MaintenancePage from "./views/MaintenancePage.vue";
import Loader from "@/components/WrapperComponents/Loader.vue";
import ToastWrapper from "@/components/SelfPaced/Discussion/ToastWrapper.vue";

export default {
  components: { ErrorPage, Loader, MaintenancePage, ToastWrapper },
  mixins: [userLanguageMixin],
  data() {
    return {
      screenWidth: 1440,
      responsiveUIRoutes: [
        "BadgeCheck",
        "Certificate",
        "Home",
        "Courses Detail",
        "SearchCourses",
        "Unauthorized"
      ],
      isRouterView: true,
      isMobile: false
    };
  },
  computed: {
    ...mapGetters([
      "allConfig",
      "getErrors",
      "language",
      "getShowFullPageLoader",
      "showMaintenancePage",
      "vueTourStatus"
    ]),
    show() {
      let ua = window.navigator.userAgent;
      if (
        ua.indexOf("MSIE") > 0 ||
        ua.indexOf("Trident/") > 0 ||
        ua.indexOf("Edge") > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    layout: {
      get() {
        return this.getLayout;
      },

      set(layout) {
        this.$store.commit("SET_LAYOUT", layout);
      }
    },
    getClassBasedOnRoute() {
      let className = "";
      if (this.isRouterView && this.$route.path.includes("self-paced")) {
        className = "app-screen__vle";
      } else if (!this.isRouterView) {
        className = "app-screen__mobile";
      }
      return className;
    }
  },
  watch: {
    allConfig(v) {
      if (v) {
        this.$store.dispatch("getAllConstants");
      }
    },
    getErrors(e) {
      if (
        e.status === 401 &&
        ["ViewPost", "Courses Detail"].includes(this.$route.name)
      ) {
        return;
      }
      var regEx = /^[4-5][0-9][0-9]$/;
      if (e.status === 401) {
        this.$keycloak.onTokenExpired = () => {
          this.$keycloak.updateToken(5).then(() => {});
        };
        // The Keycloak object uses the access token to check the user auth status
        if (this.$keycloak.authenticated === true) {
          this.$keycloak.authenticated = false;
        }
        this.$router.push({
          name: "Unauthorized",
          query: { next: this.$route.path }
        });
      } else if (
        regEx.test(e.status) &&
        !["ViewPost", "Courses Detail"].includes(this.$route.name)
      ) {
        this.createToast(e.detail, e.title, "error");
      }
    },
    screenWidth() {
      this.showRouterView();
    },
    language(v) {
      v
        ? this.$i18n.i18next.changeLanguage(v)
        : this.$i18n.i18next.changeLanguage("en");
      this.setLanguage();
    },
    $route(to) {
      this.showRouterView();
      if (to.path === "/" && this.$keycloak.authenticated) {
        this.$router.push({ path: "/loading" });
      }
    }
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
    this.setLanguage();
    this.isMobile = this.isMobileBrowser();
  },
  mounted() {
    this.screenWidth = window.innerWidth;
    setTimeout(() => {
      this.checkAndSetNewUserLanguage();
      this.checkSystemAlert();
    }, 50);
  },
  methods: {
    onPageClick(e) {
      if (e.target.nodeName !== "path" && e.target.nodeName !== "svg") {
        if (e.target.className.indexOf("card") === -1) {
          this.$root.$emit("bv::hide::tooltip");
        }
      }
      if (document.getElementById("rasaWebchatPro") !== null) {
        const element = document.getElementsByClassName("rw-open-launcher");
        if (element.length === 0) {
          document.querySelector(".rw-launcher")?.click?.();
        }
      }
    },
    myEventHandler(e) {
      this.screenWidth = e.target.innerWidth;
    },
    showRouterView() {
      const { authenticated } = this.$keycloak;
      const { name } = this.$route;
      const responsivePages = name === "Onboarding" || name === "Loading";
      let isResponsiveUIRoute = !authenticated;
      if (authenticated) {
        if (this.screenWidth < 992 || this.isMobile) {
          if (responsivePages) {
            isResponsiveUIRoute = true;
          }
        } else {
          isResponsiveUIRoute = true;
        }
      }
      this.isRouterView = isResponsiveUIRoute;
    },
    setLanguage() {
      let arr = [
        {
          id: "ar",
          name: this.$i18n.t("data.language.ar"),
          displayName: "Arabic",
          group: "un"
        },
        {
          id: "zh",
          name: this.$i18n.t("data.language.zh"),
          displayName: "Chinese",
          group: "un"
        },
        {
          id: "en",
          name: this.$i18n.t("data.language.en"),
          displayName: "English",
          group: "un"
        },
        {
          id: "fr",
          name: this.$i18n.t("data.language.fr"),
          displayName: "French",
          group: "un"
        },
        {
          id: "ru",
          name: this.$i18n.t("data.language.ru"),
          displayName: "Russian",
          group: "un"
        },
        {
          id: "es",
          name: this.$i18n.t("data.language.es"),
          displayName: "Spanish",
          group: "un"
        },
        {
          id: "bs",
          name: this.$i18n.t("data.language.bs"),
          displayName: "Bosnian",
          group: "other"
        },
        {
          id: "cs",
          name: this.$i18n.t("data.language.cs"),
          displayName: "Czech",
          group: "other"
        },
        {
          id: "et",
          name: this.$i18n.t("data.language.et"),
          displayName: "Estonian",
          group: "other"
        },
        {
          id: "fil",
          name: this.$i18n.t("data.language.fil"),
          displayName: "Filipino",
          group: "other"
        },
        {
          id: "hr",
          name: this.$i18n.t("data.language.hr"),
          displayName: "Croatian",
          group: "other"
        },
        {
          id: "hy",
          name: this.$i18n.t("data.language.hy"),
          displayName: "Armenian",
          group: "other"
        },
        {
          id: "it",
          name: this.$i18n.t("data.language.it"),
          displayName: "Italian",
          group: "other"
        },
        {
          id: "lt",
          name: this.$i18n.t("data.language.lt"),
          displayName: "Lithuanian",
          group: "other"
        },
        {
          id: "pl",
          name: this.$i18n.t("data.language.pl"),
          displayName: "Polish",
          group: "other"
        },
        {
          id: "pt",
          name: this.$i18n.t("data.language.pt"),
          displayName: "Portuguese",
          group: "other"
        },
        {
          id: "ro",
          name: this.$i18n.t("data.language.ro"),
          displayName: "Romanian",
          group: "other"
        },
        {
          id: "sl",
          name: this.$i18n.t("data.language.sl"),
          displayName: "Slovenian",
          group: "other"
        },
        {
          id: "tr",
          name: this.$i18n.t("data.language.tr"),
          displayName: "Turkish",
          group: "other"
        },
        {
          id: "uk",
          name: this.$i18n.t("data.language.uk"),
          displayName: "Ukrainian",
          group: "other"
        }
      ];
      this.$store.commit("SET_ALL_LANGUAGES", arr);
    },
    isMobileBrowser() {
      const mobileKeywords = /Mobile|Android|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|webOS|Windows Phone/i;
      const userAgent = navigator.userAgent;
      return mobileKeywords.test(userAgent);
    },
    checkSystemAlert() {
      this.$store.dispatch("getSystemAlertStatus");
    }
  }
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import "./scss/v-tour.scss";

#app {
  font-family: "Roboto", "Source Sans Pro", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

.h-100 {
  height: 100%;
}

#nav {
  padding: 30px;
}

.btn.ob-btn {
  background: #fff !important;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  color: #0043ac;
  @include button-label;
  line-height: 16px !important;
  outline: none;

  &.disabled {
    color: #c4c4c4 !important;
    background: rgba(117, 117, 117, 0.2) !important;
    opacity: 1;
  }

  &:active,
  &:hover,
  &:focus {
    color: #0043ac !important;
    box-shadow: none !important;
    background: #fff !important;
    border: none !important;
  }

  &.ob-btn-primary {
    background: #0043ac !important;
    color: #fff;

    &:active,
    &:hover,
    &:focus {
      color: #fff !important;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.ob-btn-vle-primary {
    background: $brand-vle-primary !important;
    color: #f9fafb;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    letter-spacing: 1.25px;

    &:active,
    &:hover,
    &:focus {
      color: #fff !important;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.ob-btn-outline {
    background: transparent !important;
    border: 1px solid #0043ac;

    &:active,
    &:hover,
    &:focus {
      background: #c5deff !important;
      border: 1px solid #0043ac !important;
    }
  }
}

.alert {
  &.lxp-alert {
    margin-bottom: 0;
    padding: 10px 12px;
    background: #fff;
    font-size: 12px;
    color: #000;

    button {
      padding: 10px 12px;
      font-size: 20px;
    }
  }
}
</style>
<style lang="scss" scoped>
.app__full-page-loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  display: none;

  @include z-index(modal);

  &.app__full-page-loader-wrapper--active {
    display: block;
  }

  .app__full-page-loader-container {
    height: 100%;
    @include center;
  }
}
.app__router-view-wrapper {
  > main {
    height: 100%;
  }
}
.ul-tour__backdrop {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(45, 45, 45, 0.25);
  backdrop-filter: blur(4px);
  z-index: 10;
}
</style>
