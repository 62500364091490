export default {
  SET_ENROLLED_COURSES(state, courses) {
    state.enrolledCourses = courses;
  },
  SET_ARCHIVED_COURSES(state, courses) {
    state.archivedCourses = courses;
  },
  SET_ALL_CERTIFICATES(state, certificates) {
    state.certificates = certificates;
  },
  SET_CERTIFICATE_DETAIL(state, detail) {
    state.currentCertificate = detail;
  },
  SET_AWARD_DETAIL(state, detail) {
    state.awardDetail = detail;
  }
};
