export default {
  RESET_SELF_PACED_STATES(state) {
    state.currentSequenceId = "";
    state.currentUnitId = "";
    state.unitsBySequenceId = [];
    state.currentMenuItem = "modules";
  },
  SET_UNITS_BY_SEQUENCE_ID(state, units) {
    state.unitsBySequenceId = units;
  },
  SET_CURRENT_SEQUENCE_ID(state, currentSequenceId) {
    state.currentSequenceId = currentSequenceId;
  },
  SET_CURRENT_UNIT_ID(state, currentUnitId) {
    state.currentUnitId = currentUnitId;
  },
  GO_TO_NEXT_MODULE(state, nextSequence) {
    // to get the rootState use `this.state`
    // https://github.com/vuejs/vuex/issues/344#issuecomment-517305608

    if (this.getters.isLastSequence === false) {
      state.currentUnitId = nextSequence.units[0].id;
      state.currentSequenceId = nextSequence.id;
    }
  },
  GO_TO_PREVIOUS_MODULE(state, previousSequence) {
    // to get the rootState use `this.state`
    // https://github.com/vuejs/vuex/issues/344#issuecomment-517305608
    if (this.getters.isFirstSequence === false) {
      state.currentSequenceId = previousSequence.id;
      state.currentUnitId =
        previousSequence.units[previousSequence.units.length - 1].id;
    }
  },
  SET_CURRENT_MENU_ITEM(state, menuItem) {
    state.currentMenuItem = menuItem;
  },
  SET_MODULE_TO_CHECK_MC(state, moduleToCheckMC) {
    state.moduleToCheckMC = moduleToCheckMC;
  },
  SET_IFRAME_CONTENT_STATUS(state, value) {
    state.iframeContentStatus = value;
  },
  TOGGLE_VLE_SIDE_NAV(state, value = null) {
    value !== null
      ? (state.expandVleSideNav = value)
      : (state.expandVleSideNav = !state.expandVleSideNav);
  },
  SET_USER_CREDENTIALS(state, credentials) {
    state.credentials = credentials;
  },
  SET_MILESTONES(state, milestones) {
    state.milestones = milestones;
  }
};
