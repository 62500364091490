import Vue from "vue";
import VueRouter from "vue-router";
import utils from "./utils";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    // public access
    meta: { requiresAuth: false }
  },
  {
    path: "/coursewares",
    name: "Courses",
    component: () => import("../views/Coursewares.vue"),
    // public access
    meta: { requiresAuth: false }
  },
  {
    path: "/coursewares/search",
    name: "SearchCourses",
    component: () => import("../views/SearchCoursewares.vue"),
    // public access
    meta: { requiresAuth: false }
  },
  {
    path: "/loading",
    name: "Loading",
    component: () => import("../views/Loading.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/onboarding",
    name: "Onboarding",
    component: () => import("../views/Onboarding.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/onboarding/:step",
    name: "OnboardingStep",
    component: () => import("../views/Onboarding.vue"),
    props: route => ({ step: Number(route.params.step) }),
    meta: { requiresAuth: true }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue")
  },
  {
    path: "/coursewares/:id",
    name: "Courses Detail",
    component: () => import("../views/CoursewareDetailWrapper.vue"),
    // public access
    meta: { requiresAuth: false }
  },
  {
    path: "/self-paced/:id/forums",
    component: () => import("../components/SelfPaced/ForumsWrapper.vue"),
    children: [
      {
        path: "",
        name: "Forums",
        component: () =>
          import("../components/SelfPaced/Discussion/DiscussionPostList.vue")
      },
      {
        path: ":topic_id/view",
        name: "Topics",
        component: () =>
          import("../components/SelfPaced/Discussion/DiscussionPostList.vue")
      },
      {
        path: "add",
        name: "AddPost",
        component: () =>
          import("../components/SelfPaced/Discussion/ForumsAddPost.vue")
      },
      {
        path: ":topic_id/add",
        name: "TopicPost",
        component: () =>
          import("../components/SelfPaced/Discussion/ForumsAddPost.vue")
      },
      {
        path: ":postId",
        name: "ViewPost",
        meta: { requiresAuth: false },
        component: () =>
          import("../components/SelfPaced/Discussion/DiscussionViewPost.vue")
      },
      {
        path: ":postId/edit",
        name: "Edit Post",
        component: () =>
          import("../components/SelfPaced/Discussion/ForumsAddPost.vue")
      },
      {
        path: ":postId/draft",
        name: "Draft Post",
        component: () =>
          import("../components/SelfPaced/Discussion/ForumsAddPost.vue")
      }
    ]
  },
  {
    path: "/self-paced/:id",
    name: "Self Paced",
    component: () => import("../views/SelfPaced.vue"),
    children: [
      {
        path: "teams",
        component: () => import("../components/Teams/TeamsWrapper.vue"),
        children: [
          {
            path: "",
            name: "TeamsView",
            component: () => import("@/components/Teams/TeamsTopics.vue")
          },
          {
            path: ":topicId/list",
            name: "TeamList",
            component: () => import("@/components/Teams/TeamList.vue")
          },
          {
            path: ":topicId/:teamId",
            name: "TeamDetail",
            component: () => import("@/components/Teams/TeamsDetail.vue")
          },
          {
            path: "activity/:activityId/feed",
            name: "ActivityFeed",
            component: () => import("@/components/Teams/ActivityFeed.vue")
          },
          {
            path: "activity/:activityId/feed/:feedId",
            name: "AcitvityFeedDetail",
            component: () => import("@/components/Teams/ActivityFeedDetail.vue")
          }
        ]
      }
    ]
  },
  {
    path: "/xblock/:id",
    name: "xblock",
    component: () => import("../views/XBlock.vue")
  },
  {
    path: "/my-learning",
    name: "my-learning",
    component: () => import("../views/MyLearning.vue")
  },
  // new achievements
  {
    path: "/achievements",
    name: "achievements",
    component: () => import("../views/Achievements.vue")
  },
  {
    path: "/achievements/:id",
    name: "Achievements Detail",
    component: () => import("@/components/MyAchievements/Awards.vue"),
    // public access
    meta: { requiresAuth: false }
  },
  // old achievements
  {
    path: "/legacy-achievements",
    name: "legacy-achievements",
    component: () => import("../views/MyAchievements.vue")
  },
  {
    path: "/legacy-achievements/:id",
    name: "legacy-achievement-details",
    component: () => import("@/components/Achievements/Detail.vue")
  },
  {
    path: "/legacy-achievements/:id/awards/:type",
    name: "awards",
    component: () => import("@/components/Achievements/CertificateWrapper.vue")
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../views/Settings.vue")
  },
  {
    path: "/course-discovery",
    name: "course-discovery",
    component: () => import("../views/CourseDiscovery.vue")
  },
  {
    path: "/discovery",
    name: "discovery",
    component: () => import("../views/Discovery.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/awards/:id/:type",
    name: "awards-share",
    component: () => import("../views/Awards.vue"),
    // public access
    meta: { requiresAuth: false }
  },
  {
    path: "/badge-check",
    name: "BadgeCheck",
    component: () => import("../views/BadgeCheck.vue")
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: () => import("../views/Unauthorized.vue"),
    // public access
    meta: { requiresAuth: false }
  },
  {
    path: "/learning-space/:org",
    name: "LearningSpace",
    component: () => import("../views/LearningSpace.vue")
  },
  {
    path: "/partners/:org",
    name: "PartnersDetail",
    component: () => import("../views/PartnersLanding.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: () => import("../views/TermsAndConditions.vue"),
    // public access
    meta: { requiresAuth: false }
  },
  {
    path: "/accessibility-statement",
    name: "AccessibilityStatement",
    component: () => import("../views/AccessibilityStatement.vue"),
    // public access
    meta: { requiresAuth: false }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // public routes
  if (to.meta.requiresAuth === false) {
    checkCourswarePathAndProceed(from, to, next);
  } else {
    // protected routes
    if (
      Vue.prototype.$keycloak &&
      Vue.prototype.$keycloak.idTokenParsed &&
      Vue.prototype.$keycloak.idTokenParsed.sub
    ) {
      // authenticated user
      checkCourswarePathAndProceed(from, to, next);
    } else {
      next({ name: "Home", query: { next: to.path } });
    }
  }
});

// the courseware path is public as well as protected
// so we need to check for both cases
const checkCourswarePathAndProceed = (from, to, next) => {
  // since we encode course id to send it as a enocoded url(param) for redirect_uri.
  // check if route has encode path and decode the params.
  if (
    from.name === "ViewPost" &&
    to.name === "Courses Detail" &&
    !Vue.prototype.$keycloak.authenticated
  ) {
    localStorage.setItem("postId", from.params.postId);
    next();
  }
  if (
    to.path.indexOf("coursewares") !== -1 &&
    utils.checkEncodeURI([to.path])
  ) {
    to.params.id = decodeURIComponent(to.params.id);
    next({ path: `/coursewares/${to.params.id}` });
  } else {
    next();
  }
};

export default router;
